import {
  FINANCIAL_REPORTS_SUCCESS,
  FINANCIAL_REPORTS_COMPLETE_SUCCESS,
  FINANCIAL_SUMMARY_REPORTS_COMPLETE_SUCCESS,
  FINANCIAL_REPORTS_FAILED,
  CALCULATION_REPORTS_SUCCESS,
  TARGET_REPORTS_SUCCESS,
  RATIO_KEY_FIGURES_REPORTS_SUCCESS,
  TOP_N_ATTRIBUTES_REPORTS_SUCCESS,
  DUPLICATE_PAYMENT_REPORTS_SUCCESS,
  ANAMOLY_REPORTS_SUCCESS,
  APPROVAL_POSTING_HISTORY_REPORTS_SUCCESS,
  CONTRACT_SEARCH_BY_CUSTOMER,
  CALCULATION_FORMULA_VALUES_REPORTS_SUCCESS,
  CONTRACT_CHANGE_REPORTS_SUCCESS,
  FINANCIAL_SUMMARY_SUCCESS,
  CONFIG_CHANGE_HISTORY_SUCCESS,
  CALCULATION_REPORTS_COMPLETE_SUCCESS,
  TARGET_REPORTS_COMPLETE_SUCCESS,
  CONTRACT_CHANGE_REPORTS_COMPLETE_SUCCESS,
  FINANCIAL_SUMMARY_COMPLETE_SUCCESS,
  CALCULATION_REPORTS_FAILED,
  FINANCIAL_SUMMARY_REPORTS_COMPLETE_FAILED,
  TARGET_REPORTS_FAILED,
  CONTRACT_CHANGE_REPORTS_FAILED,
  FINANCIAL_SUMMARY_FAILED,
  INVOICE_REPORTS_SUCCESS,
  INVOICE_REPORTS_COMPLETE_SUCCESS,
  INVOICE_REPORTS_FAILED,
  CONTRACT_REPORTS_SUCCESS,
  CONTRACT_REPORTS_COMPLETE_SUCCESS,
  CONTRACT_REPORTS_FAILED,
  FETCH_START,
  FETCH_END,
  CONTRACT_REPORTS_OVERLAP_SUCCESS,
  DYNAMIC_QUERY_FIELDS_SUCCESS,
  DYNAMIC_QUERY_FIELDS_SUCCESS_NEW,
  QUERY_LIST_SUCCESS,
  QUERY_RESULTS_FINAL_SUCCESS,
  DYNAMIC_QUERY_DETAILS_SUCCESS,
  CLAIMS_FORMAT_FIELDS_SUCCESS,
  INCOMING_CLAIM_DETAILS_SUCCESS,
  QUERY_LIST_ADMIN_SUCCESS,
  QUERY_TYPES_SUCCESS,
  LOAD_TARGET_DATA,
  COLL_PORTAL_CUST_SUPP,
  OUTGOING_CLAIMS_FORMAT_FIELDS_SUCCESS,
  OUTGOING_CLAIM_DETAILS_SUCCESS,
  GET_ALL_ORG_USERS,
  TABLE_DETERMINATION_SUCCESS,
  PREDEFINED_QUERY_RESULTS_FINAL_SUCCESS,
  PREDEFINED_QUERY_DETAILS_SUCCESS,
  DYNAMIC_QUERY_FILTER,
  DYNAMIC_QUERY_FILTER_BY_ID
} from "./ActionTypes";
import axios from "../../../../axios.conf";
import { history } from "../../../../components/Helpers";
import { toast } from "react-toastify";
import { ServerError } from "../../../../library/constants";
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const financialReportsSuccess = (data) => {
  return {
    type: FINANCIAL_REPORTS_SUCCESS,
    data,
  };
};

export const financialReportsFailed = (data) => {
  return {
    type: FINANCIAL_REPORTS_FAILED,
    data,
  };
};

export const runFinancialSummaryReports = (pagination, limit, keyword) => {
  return (dispatch) => {
    runFinancialSummaryReportsAPI(pagination, limit, keyword)
      .then((response) => {
        dispatch(
          runFinancialSummaryReportsComplete(
            response.data.response.total_record
          )
        );
        dispatch(fetchEnd());
        if (response.data.response.total_record > 0) {
          dispatch(financialReportsSuccess(response.data.response));
          history.push("/financial-approvals-reports/results");
        }
      })
      .catch((error) => {
      });
  };
};
const runFinancialSummaryReportsAPI = (pagination, limit, keyword) => {
  let data = JSON.parse(localStorage.getItem("FinancialSummaryReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get(
    "financial_postings/grouped?page=" + pagination + "&limit=" + limit,
    { params: data },
    { headers: headers }
  );
  return result;
};

export const runFinancialReports = (pagination, limit, keyword) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getFinancialReportsAPi(pagination, limit, keyword)
      .then((response) => {
        dispatch(
          runFinancialReportsComplete(response.data.response.total_record)
        );
        dispatch(fetchEnd());
        if (response.data.response.total_record > 0) {
          dispatch(financialReportsSuccess(response.data.response));
          history.push("/financial-postings-reports/financial-reports");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(financialReportsFailed(error));
      });
  };
};

const getFinancialReportsAPi = (pagination, limit, keyword) => {
  let data = JSON.parse(sessionStorage.getItem("FinancialReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (keyword === "noFilter") {
    result = axios.get(
      "financial_postings?page=" + pagination + "&limit=" + limit,
      { headers: headers }
    );
  } else if (keyword) {
    result = axios.get(
      "financial_postings?page=" +
      pagination +
      "&limit=" +
      limit +
      "&keyword=" +
      keyword,
      { params: data },
      { headers: headers }
    );
  } else {
    result = axios.get(
      "financial_postings?page=" + pagination + "&limit=" + limit,
      { params: data },
      { headers: headers }
    );
  }
  return result;
};

export const generateFinancialReports = (pagination, limit, keyword) => {
  return (dispatch) => {
    dispatch(fetchStart());
    generateFinancialReportsApi(pagination, limit, keyword)
      .then((response) => {
        if (response.data.response.total_record > 0) {
          let result = response.data.response.records.map(
            ({
              contract_number,
              incentive_rate,
              incentive_amount,
              incentive_basis,
            }) => ({
              contract_number,
              incentive_rate,
              incentive_amount,
              incentive_basis,
            })
          );
          dispatch(generatePDF(result));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(financialReportsFailed(error));
      });
  };
};

export const generatePDF = (data) => {
  return (dispatch) => {
    generatePDFApi(data).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      let date = Date.now();
      link.setAttribute("download", date + ".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
};

const generatePDFApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.post("generate-pdf", data, {
    headers: headers,
    responseType: "blob",
  });
  return result;
};

const generateFinancialReportsApi = (pagination, limit, keyword) => {
  let data = JSON.parse(sessionStorage.getItem("FinancialReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get(
    "financial_postings?limit=0",
    { params: data },
    { headers: headers }
  );
  return result;
};

export const financialReportsCompleteSuccess = (data) => {
  return {
    type: FINANCIAL_REPORTS_COMPLETE_SUCCESS,
    data,
  };
};

export const runFinancialReportsComplete = (totalRecords) => {
  return (dispatch) => {
    getFinancialReportsCompleteApi(totalRecords)
      .then((response) => {
        if (response.data.response.total_record > 0) {
          dispatch(financialReportsCompleteSuccess(response.data.response));
        }
      })
      .catch((error) => { });
  };
};

const getFinancialReportsCompleteApi = (totalRecords) => {
  let data = JSON.parse(sessionStorage.getItem("FinancialReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.get(
    "financial_postings?limit=" + totalRecords,
    { params: data },
    { headers: headers }
  );
  return result;
};

export const financialSummaryReportsCompleteSuccess = (data) => {
  return {
    type: FINANCIAL_SUMMARY_REPORTS_COMPLETE_SUCCESS,
    data,
  };
};
export const financialSummaryReportsCompleteFailed = (data) => {
  return {
    type: FINANCIAL_SUMMARY_REPORTS_COMPLETE_FAILED,
    data,
  };
};

export const runFinancialSummaryReportsComplete = (totalRecords) => {
  return (dispatch) => {
    getFinancialSummaryReportsCompleteApi(totalRecords)
      .then((response) => {
        if (response.data.response.total_record > 0) {
          dispatch(
            financialSummaryReportsCompleteSuccess(response.data.response)
          );
        }
      })
      .catch((error) => { });
  };
};

const getFinancialSummaryReportsCompleteApi = (totalRecords) => {
  let data = JSON.parse(localStorage.getItem("FinancialSummaryReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.get(
    "financial_postings/grouped?limit=" + totalRecords,
    { params: data },
    { headers: headers }
  );
  return result;
};

export const calculationReportsSuccess = (data) => {
  return {
    type: CALCULATION_REPORTS_SUCCESS,
    data,
  };
};

export const calculationReportsFailed = (data) => {
  return {
    type: CALCULATION_REPORTS_FAILED,
    data,
  };
};

export const runCalculationReports = (pagination, limit, keyword) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCalculationReportsAPi(pagination, limit, keyword)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.total_record > 0) {
          dispatch(fetchEnd());
          dispatch(calculationReportsSuccess(response.data.response));
          history.push("/calculation-simulation-reports/calculation-reports");
          dispatch(
            runCalculationReportsComplete(response.data.response.total_record)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(calculationReportsFailed(error));
      });
  };
};

const getCalculationReportsAPi = (pagination, limit, keyword) => {
  const data = JSON.parse(sessionStorage.getItem("CalculationReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (keyword) {
    result = axios.get(
      "calculation_simulations?page=" +
      pagination +
      "&limit=" +
      limit +
      "&keyword=" +
      keyword,
      { params: data },
      { headers: headers }
    );
  } else {
    result = axios.get(
      "calculation_simulations?page=" + pagination + "&limit=" + limit,
      { params: data },
      { headers: headers }
    );
  }
  return result;
};

export const targetReportsSuccess = (data) => {
  return {
    type: TARGET_REPORTS_SUCCESS,
    data,
  };
};

export const targetReportsFailed = (data) => {
  return {
    type: TARGET_REPORTS_FAILED,
    data,
  };
};

export const runTargetReports = (pagination, limit, keyword) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getTargetReportsAPi(pagination, limit, keyword)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.total_record > 0) {
          dispatch(fetchEnd());
          dispatch(targetReportsSuccess(response.data.response));
          history.push("/target-data-reports/target-reports-results");
          // dispatch(
          //   runTargetReportsComplete(response.data.response.total_record)
          // );
        } else {
          toast.warning("No records found.");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(targetReportsFailed(error));
      });
  };
};

const getTargetReportsAPi = (pagination, limit, keyword) => {
  const data = JSON.parse(sessionStorage.getItem("TargetReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (keyword) {
    result = axios.get(
      "target-data-report?page=" +
      pagination +
      "&limit=" +
      limit +
      "&keyword=" +
      keyword,
      { params: data },
      { headers: headers }
    );
  } else {
    result = axios.get(
      "target-data-report?page=" + pagination + "&limit=" + limit,
      { params: data },
      { headers: headers }
    );
  }
  return result;
};
// calculation formula values 
export const runCalculationFormulaValuesReports = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCalculationFormulaValuesReportsAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.length > 0) {
          dispatch(fetchEnd());
          dispatch(calculationFormulaValuesReportsSuccess(response.data.response));
          history.push("/calulate-formula-values-reports-results");
        } else {
          toast.warning("No records found.");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getCalculationFormulaValuesReportsAPi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result = axios.post("formula-calculation?app_type=Customer Rebate&data_source_type=Direct - Sales Data", data, { headers: headers });
  return result;
};
export const calculationFormulaValuesReportsSuccess = (data) => {
  return {
    type: CALCULATION_FORMULA_VALUES_REPORTS_SUCCESS,
    data,
  };
};
// ratio key figures reports
export const runRatioKeyFiguresReports = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getRatioKeyFiguresReportsAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.length > 0) {
          dispatch(fetchEnd());
          dispatch(ratioKeyFiguresReportsSuccess(response.data.response));
          history.push("/ratio-key-figures-reports-results");
        } else {
          toast.warning("No records found.");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(targetReportsFailed(error));
      });
  };
};

const getRatioKeyFiguresReportsAPi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result = axios.post("attribute-ratio?app_type=Customer Rebate&data_source_type=Direct - Sales Data", data, { headers: headers });
  return result;
};
export const ratioKeyFiguresReportsSuccess = (data) => {
  return {
    type: RATIO_KEY_FIGURES_REPORTS_SUCCESS,
    data,
  };
};
// Top N attributes Report
export const runTopNAttributesReports = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getTopNAttributesReportsAPi(data, id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.length > 0) {
          dispatch(fetchEnd());
          dispatch(topNAttributesReportsSuccess(response.data.response));
          history.push("/top-N-attributes-reports-results");
        } else {
          toast.warning("No records found.");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(targetReportsFailed(error));
      });
  };
};

const getTopNAttributesReportsAPi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result = axios.post("top-attribute-share?app_type=Customer Rebate&data_source_type=Direct - Sales Data&request_id=" + id, data, { headers: headers });
  return result;
};
export const topNAttributesReportsSuccess = (data) => {
  return {
    type: TOP_N_ATTRIBUTES_REPORTS_SUCCESS,
    data,
  };
};
export const runCancelRequest = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCancelRequestAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.message);

      })
      .catch((error) => {
        dispatch(fetchEnd());
        toast.error(error.response.data.response)
      });
  };
};

const getCancelRequestAPi = (data) => {
  const config = {
    headers: {
      Authorization: secureLocalStorage.getItem("aTk"),
    },
    params: data
  }
  let result = axios.get("cancel-request", config);
  return result;
};
// duplicate payment Report
export const runDuplicatePaymentReports = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getDuplicatePaymentReportsAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response != null && response.data.response.length > 0) {
          dispatch(fetchEnd());
          dispatch(duplicatePaymentReportsSuccess(response.data.response));
          history.push("/duplicate-payment-reports-results");
        } else {
          toast.warning("No records found.");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(targetReportsFailed(error));
      });
  };
};

const getDuplicatePaymentReportsAPi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result = axios.post("duplicate-payment", data, { headers: headers });
  return result;
};
export const duplicatePaymentReportsSuccess = (data) => {
  return {
    type: DUPLICATE_PAYMENT_REPORTS_SUCCESS,
    data,
  };
};
// Anamoly reports
export const runAnamolyReports = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAnamolyReportsAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response !== 'no anamolies detected' && response.data.response.length > 0) {
          dispatch(fetchEnd());
          dispatch(anamolyReportsSuccess(response.data.response));
          history.push("/anamoly-reports-results");
        } else {
          toast.warning(response.data.response);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(targetReportsFailed(error));
      });
  };
};

const getAnamolyReportsAPi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result = axios.post("detect-anamolies", data, { headers: headers });
  return result;
};
export const anamolyReportsSuccess = (data) => {
  return {
    type: ANAMOLY_REPORTS_SUCCESS,
    data,
  };
};
// approval posting History
export const runApprovalPostingHistoryReports = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getApprovalPostingHistoryReportsAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response != null && response.data.response.length > 0) {
          dispatch(fetchEnd());
          dispatch(getApprovalPostingHistorySucsses(response.data.response));
          history.push("/approval-posting-history-reports-results");
        } else {
          toast.warning("No records found.");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(targetReportsFailed(error));
      });
  };
};

const getApprovalPostingHistoryReportsAPi = (data) => {
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result = axios.post("approval-history?application_type=Customer Rebate", data, { headers: headers });
  return result;
};
export const getApprovalPostingHistorySucsses = (data) => {
  return {
    type: APPROVAL_POSTING_HISTORY_REPORTS_SUCCESS,
    data,
  };
};

//Contract Search By Customer
export const runContractSearchByCustomer = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getContractSearchByCustomerAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data) {
          dispatch(fetchEnd());
          dispatch(getContractSearchByCustomerSucsses(response.data));
          history.push("/contract-search-by-customer-results");
        } else {
          toast.warning("No records found.");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getContractSearchByCustomerAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  // let result = axios.get("contracts/customer-details", {
  //   headers: headers,
  //   params: data,
  // });
  let result = axios.get("contracts/customer-details?" + data, {
    headers: headers,
  });
  return result;
};
export const getContractSearchByCustomerSucsses = (data) => {
  return {
    type: CONTRACT_SEARCH_BY_CUSTOMER,
    data,
  };
};

export const contractChangeReportsSuccess = (data) => {
  return {
    type: CONTRACT_CHANGE_REPORTS_SUCCESS,
    data,
  };
};

export const contractChangeReportsFailed = (data) => {
  return {
    type: CONTRACT_CHANGE_REPORTS_FAILED,
    data,
  };
};

export const runcontractChangeReports = (pagination, limit, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getcontractChangeReportsAPi(pagination, limit, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (
          response &&
          response.data &&
          response.data.response &&
          response.data.response.total_record &&
          response.data.response.total_record > 0
        ) {
          dispatch(fetchEnd());
          dispatch(contractChangeReportsSuccess(response.data.response));
          history.push("/contract-change-reports/result");
        } else {
          toast.warning("No records found.");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(contractChangeReportsFailed(error));
      });
  };
};

const getcontractChangeReportsAPi = (pagination, limit, type) => {
  const data = JSON.parse(sessionStorage.getItem("ContractChangeReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (type === "all-changes") {
    result = axios.get(
      "audit-contract-list?page=" + pagination + "&limit=" + limit + "&application_type=" + sessionStorage.getItem("application"),
      { params: data },
      { headers: headers }
    );
  }
  else if (type === "pending-changes") {
    result = axios.get(
      "audit-contract-list?page=" + pagination + "&limit=" + limit + "&pending-change=true" + "&application_type=" + sessionStorage.getItem("application"),
      { params: data },
      { headers: headers }
    );
  }
  else if (type === "approved") {
    result = axios.get(
      "audit-contract-list?page=" + pagination + "&limit=" + limit + "&approved=true" + "&application_type=" + sessionStorage.getItem("application"),
      { params: data },
      { headers: headers }
    );
  }
  else {
    result = axios.get(
      "contract-change-log-list?page=" + pagination + "&limit=" + limit,
      { params: data },
      { headers: headers }
    );
  }
  return result;
};

export const financialSummarySuccess = (data) => {
  return {
    type: FINANCIAL_SUMMARY_SUCCESS,
    data,
  };
};

export const financialSummaryFailed = (data) => {
  return {
    type: FINANCIAL_SUMMARY_FAILED,
    data,
  };
};

export const getConfigChangeHistoryData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getConfigChangeHistoryDataApi(data).then(
      (response) => {
        dispatch(fetchEnd());
        if (response && response.data) {
          dispatch(getConfigChangeHistoryDataSuccess(response.data));
          history.push("/change-control-result");
        } else {
          // toast.error("No records found");
        }
      }
    );
  };
};
const getConfigChangeHistoryDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  return axios.get("/config-change-history", { params: data }, { headers: headers });
};

export const getConfigChangeHistoryDataSuccess = (data) => {
  return {
    type: CONFIG_CHANGE_HISTORY_SUCCESS, // Define this action type in your reducer
    data,
  };
};


export const runFinancialSummary = (pagination, limit, keyword) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getFinancialSummaryAPi(pagination, limit, keyword)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.total_record > 0) {
          dispatch(fetchEnd());
          dispatch(financialSummarySuccess(response.data.response));
          history.push("/financial-approvals-reports/result");
          dispatch(
            runFinancialSummaryComplete(response.data.response.total_record)
          );
        } else {
          //toast.error("Zero records found.");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(financialSummaryFailed(error));
      });
  };
};

const getFinancialSummaryAPi = (pagination, limit, keyword) => {
  const data = JSON.parse(localStorage.getItem("FinancialSummaryPosting"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (keyword) {
    result = axios.get(
      "financial_postings/grouped?page=" +
      pagination +
      "&limit=" +
      limit +
      "&keyword=" +
      keyword,
      { params: data },
      { headers: headers }
    );
  } else {
    result = axios.get(
      "financial_postings/grouped?page=" + pagination + "&limit=" + limit,
      { params: data },
      { headers: headers }
    );
  }
  return result;
};

export const invoiceReportsSuccess = (data) => {
  return {
    type: INVOICE_REPORTS_SUCCESS,
    data,
  };
};

export const invoiceReportsFailed = (data) => {
  return {
    type: INVOICE_REPORTS_FAILED,
    data,
  };
};

export const runInvoiceReports = (pagination, limit, keyword) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getInvoiceReportsAPi(pagination, limit, keyword)
      .then((response) => {
        if (response.data.response.records.length > 0) {
          dispatch(fetchEnd());
          dispatch(invoiceReportsSuccess(response.data.response));
          history.push("/invoice-details-reports/invoice-reports");
          dispatch(
            runInvoiceReportsComplete(response.data.response.total_record)
          );
        } else {
          dispatch(fetchEnd());
          toast.warning("No records found.");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(invoiceReportsFailed(error));
      });
  };
};

const getInvoiceReportsAPi = (pagination, limit, keyword) => {
  let data = JSON.parse(sessionStorage.getItem("InvoiceReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (keyword === "noFilter") {
    result = axios.get("sales_data?page=" + pagination + "&limit=" + limit, {
      headers: headers,
    });
  } else if (keyword) {
    result = axios.get(
      "sales_data?page=" +
      pagination +
      "&limit=" +
      limit +
      "&keyword=" +
      keyword,
      { params: data },
      { headers: headers }
    );
  } else {
    result = axios.get(
      "sales_data?page=" + pagination + "&limit=" + limit,
      { params: data },
      { headers: headers }
    );
  }
  return result;
};

export const invoiceReportsCompleteSuccess = (data) => {
  return {
    type: INVOICE_REPORTS_COMPLETE_SUCCESS,
    data,
  };
};

export const runInvoiceReportsComplete = (totalRecords) => {
  return (dispatch) => {
    getInvoiceReportsCompleteApi(totalRecords)
      .then((response) => {
        if (response.data.response.records.length > 0) {
          dispatch(invoiceReportsCompleteSuccess(response.data.response));
        }
      })
      .catch((error) => { });
  };
};

const getInvoiceReportsCompleteApi = (totalRecords) => {
  let data = JSON.parse(sessionStorage.getItem("InvoiceReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.get(
    "sales_data?limit=" + totalRecords,
    { params: data },
    { headers: headers }
  );
  return result;
};

export const contractReportsSuccess = (data) => {
  return {
    type: CONTRACT_REPORTS_SUCCESS,
    data,
  };
};

export const contractReportsFailed = (data) => {
  return {
    type: CONTRACT_REPORTS_FAILED,
    data,
  };
};

export const runContractReports = (pagination, limit, keyword, overlap) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getContractReportsAPI(pagination, limit, keyword, overlap)
      .then((response) => {
        dispatch(fetchEnd());
        if (overlap === "overlap") {
          if (response.data.response.length > 0) {
            dispatch(contractReportsOverlapSuccess(response.data.response));
            history.push("/contract-overlap-reports/contract-overlap-reports");
          } else {
            toast.warning("No records found.");
          }
        } else {
          if (response.data.response.records.length > 0) {
            dispatch(contractReportsSuccess(response.data.response));
            history.push("/contract-reports/contract-reports-results");
            dispatch(
              runContractReportsComplete(response.data.response.total_record)
            );
          } else {
            toast.warning("No records found.");
            dispatch(fetchEnd());
          }
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(invoiceReportsFailed(error));
      });
  };
};

export const contractReportsOverlapSuccess = (data) => {
  return {
    type: CONTRACT_REPORTS_OVERLAP_SUCCESS,
    data,
  };
};

const getContractReportsAPI = (pagination, limit, keyword, overlap) => {
  let data = JSON.parse(sessionStorage.getItem("ContractReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (keyword === "noFilter" && overlap != "overlap") {
    result = axios.get("contracts?page=" + pagination + "&limit=" + limit, {
      headers: headers,
    });
  } else if (keyword && overlap != "overlap") {
    result = axios.get(
      "contracts?page=" +
      pagination +
      "&limit=" +
      limit +
      "&keyword=" +
      keyword,
      { params: data },
      { headers: headers }
    );
  } else {
    if (overlap === "overlap" && keyword != "noFilter") {
      result = axios.get(
        "contracts?overlap=true&page=" + pagination + "&limit=" + limit,
        { params: data },
        { headers: headers }
      );
    } else if (overlap === "overlap" && keyword === "noFilter") {
      result = axios.get(
        "contracts?overlap=true&page=" + pagination + "&limit=" + limit,
        { headers: headers }
      );
    } else {
      result = axios.get(
        "contracts?page=" + pagination + "&limit=" + limit,
        { params: data },
        { headers: headers }
      );
    }
  }
  return result;
};

export const contractReportsCompleteSuccess = (data) => {
  return {
    type: CONTRACT_REPORTS_COMPLETE_SUCCESS,
    data,
  };
};

export const runContractReportsComplete = (totalRecords) => {
  return (dispatch) => {
    getContractReportsCompleteAPI(totalRecords).then((response) => {
      if (response.data.response.records.length > 0) {
        dispatch(contractReportsCompleteSuccess(response.data.response));
      }
    });
  };
};

const getContractReportsCompleteAPI = (totalRecords) => {
  let data = JSON.parse(sessionStorage.getItem("ContractReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.get(
    "contracts?limit=" + totalRecords,
    { params: data },
    { headers: headers }
  );
  return result;
};

export const downloadSheet = (data, name) => {
  return (dispatch) => {
    getDownloadSheetApi(data, name).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      let date = Date.now();
      link.setAttribute("download", date + ".xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
};

const getDownloadSheetApi = (data, name) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.post("generate-excel-sheet/" + name, data, {
    headers: headers,
    responseType: "blob",
  });
  return result;
};

export const calculationReportsCompleteSuccess = (data) => {
  return {
    type: CALCULATION_REPORTS_COMPLETE_SUCCESS,
    data,
  };
};

export const runCalculationReportsComplete = (totalRecords) => {
  return (dispatch) => {
    getCalculationReportsCompleteApi(totalRecords)
      .then((response) => {
        if (response.data.response.total_record > 0) {
          dispatch(calculationReportsCompleteSuccess(response.data.response));
        } else {
          //toast.error("Zero records found.");
        }
      })
      .catch((error) => {
      });
  };
};

const getCalculationReportsCompleteApi = (totalRecords) => {
  const data = JSON.parse(sessionStorage.getItem("CalculationReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.get(
    "calculation_simulations?limit=" + totalRecords,
    { params: data },
    { headers: headers }
  );
  return result;
};

export const targetReportsCompleteSuccess = (data) => {
  return {
    type: TARGET_REPORTS_COMPLETE_SUCCESS,
    data,
  };
};

export const runTargetReportsComplete = (totalRecords) => {
  return (dispatch) => {
    getTargetReportsCompleteApi(totalRecords)
      .then((response) => {
        if (response.data.response.total_record > 0) {
          dispatch(targetReportsCompleteSuccess(response.data.response));
        }
      })
      .catch((error) => {
      });
  };
};

const getTargetReportsCompleteApi = (totalRecords) => {
  const data = JSON.parse(sessionStorage.getItem("TargetReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.get(
    "target-data-report?limit=" + totalRecords,
    { params: data },
    { headers: headers }
  );
  return result;
};

export const contractChangeReportsCompleteSuccess = (data) => {
  return {
    type: CONTRACT_CHANGE_REPORTS_COMPLETE_SUCCESS,
    data,
  };
};

export const runContractChangeReportsComplete = (totalRecords) => {
  return (dispatch) => {
    getContractChangeReportsCompleteApi(totalRecords)
      .then((response) => {
        if (response.data.response.total_record > 0) {
          dispatch(
            contractChangeReportsCompleteSuccess(response.data.response)
          );
        } else {
          //toast.error("Zero records found.");
        }
      })
      .catch((error) => {
      });
  };
};

const getContractChangeReportsCompleteApi = (totalRecords) => {
  const data = JSON.parse(sessionStorage.getItem("ContractChangeReportsData"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.get(
    "contract-change-log-list?limit=" + totalRecords,
    { params: data },
    { headers: headers }
  );
  return result;
};

export const financialSummaryCompleteSuccess = (data) => {
  return {
    type: FINANCIAL_SUMMARY_COMPLETE_SUCCESS,
    data,
  };
};

export const runFinancialSummaryComplete = (totalRecords) => {
  return (dispatch) => {
    getFinancialSummaryCompleteApi(totalRecords)
      .then((response) => {
        if (response.data.response.total_record > 0) {
          dispatch(financialSummaryCompleteSuccess(response.data.response));
        } else {
          //toast.error("Zero records found.");
        }
      })
      .catch((error) => {
      });
  };
};

const getFinancialSummaryCompleteApi = (totalRecords) => {
  const data = JSON.parse(localStorage.getItem("FinancialSummaryPosting"));
  for (let propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.get(
    "financial_postings/grouped?limit=" + totalRecords,
    { params: data },
    { headers: headers }
  );
  return result;
};

export const searchInvoice = (keyword) => {
  return (dispatch) => {
    if (keyword) {
      getSearchInvoiceApi(keyword)
        .then((response) => {
          dispatch(invoiceReportsSuccess(response.data.response));
        })
        .catch((error) => { });
    } else {
      dispatch(runInvoiceReports(0, 10));
    }
  };
};

const getSearchInvoiceApi = (keyword) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("sales-data-search?keyword=" + keyword, {
    headers: headers,
  });
  return result;
};

export const getDynamicQueryFields = () => {
  return (dispatch) => {
    getDynamicQueryFieldsApi().then((response) => {
      dispatch(getDynamicQueryFieldsApiSuccess(response.data.response));
    });
  };
};

const getDynamicQueryFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("financial-posting-fields", { headers: headers });
  return result;
};

export const getDynamicQueryFieldsApiSuccess = (data) => {
  sessionStorage.setItem("dq_table_name", data.table_name);
  return {
    type: DYNAMIC_QUERY_FIELDS_SUCCESS,
    data,
  };
};

export const getPurchaseDataFields = () => {
  return (dispatch) => {
    getPurchaseDataFieldsAPI().then((response) => {
      dispatch(getPurchaseDataFieldsAPISuccess(response.data.response));
    });
  };
};

const getPurchaseDataFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("purchase-data-fields", { headers: headers });
  return result;
};

export const getPurchaseDataFieldsAPISuccess = (data) => {
  sessionStorage.setItem("dq_table_name", data.table_name);
  return {
    type: DYNAMIC_QUERY_FIELDS_SUCCESS,
    data,
  };
};

export const getQueryTypes = () => {
  return (dispatch) => {
    getQueryTypesAPI().then((response) => {
      dispatch(getQueryTypesAPISuccess(response.data));
    });
  };
};

const getQueryTypesAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("get-query-types", { headers: headers });
  return result;
};

export const getQueryTypesAPISuccess = (data) => {
  return {
    type: QUERY_TYPES_SUCCESS,
    data,
  };
};

export const getDynamicQuerySalesDataFields = () => {
  return (dispatch) => {
    getDynamicQuerySalesDataFieldsAPI().then((response) => {
      dispatch(
        getDynamicQuerySalesDataFieldsAPISuccess(response.data.response)
      );
    });
  };
};

const getDynamicQuerySalesDataFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("sales-data-fields", { headers: headers });
  return result;
};

export const getDynamicQuerySalesDataFieldsAPISuccess = (data) => {
  sessionStorage.setItem("dq_table_name", data.table_name);
  return {
    type: DYNAMIC_QUERY_FIELDS_SUCCESS,
    data,
  };
};

export const getCalculationSimulationDataFields = () => {
  return (dispatch) => {
    getCalculationSimulationDataFieldsAPI().then((response) => {
      dispatch(
        getCalculationSimulationDataFieldsSuccess(response.data.response)
      );
    });
  };
};

const getCalculationSimulationDataFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("calculation-simulation-fields", { headers: headers });
  return result;
};

export const getCalculationSimulationDataFieldsSuccess = (data) => {
  sessionStorage.setItem("dq_table_name", data.table_name);
  return {
    type: DYNAMIC_QUERY_FIELDS_SUCCESS,
    data,
  };
};

export const getQueryList = (pagination, limit, queryType, app) => {
  return (dispatch) => {
    getQueryListApi(pagination, limit, queryType).then((response) => {
      dispatch(getQueryListApiSuccess(response.data.response));
    });
  };
};

const getQueryListApi = (pagination, limit, queryType) => {
  const app = sessionStorage.getItem("application");
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (app === "Audit Lab") {
    result = axios.get(
      "dynamic-query-formats-all?page=" +
      pagination +
      "&query_type=" +
      queryType +
      "&limit=" +
      limit,
      { headers: headers }
    );
  } else if (sessionStorage.getItem('application') === "Collaboration Portal") {
    result = axios.get(
      "dynamic-query-formats-all?page=" +
      pagination +
      "&query_type=" +
      queryType +
      "&limit=" +
      limit,
      { headers: headers }
    );
  } else {
    result = axios.get(
      "dynamic-query-formats-all?page=" +
      pagination +
      "&query_type=" +
      queryType +
      "&limit=" +
      limit +
      "&app_type=" +
      app,
      { headers: headers }
    );
  }
  return result;
};

export const getQueryListApiSuccess = (data) => {
  return {
    type: QUERY_LIST_SUCCESS,
    data,
  };
};

export const getQueryListAdmin = () => {
  return (dispatch) => {
    getQueryListAdminAPI().then((response) => {
      dispatch(getQueryListAdminSuccess(response.data.response));
    });
  };
};

const getQueryListAdminAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("admin-dynamic-query-formats-all", { headers: headers });
  return result;
};

export const getQueryListAdminSuccess = (data) => {
  return {
    type: QUERY_LIST_ADMIN_SUCCESS,
    data,
  };
};

export const postDynamicQuery = (data, mode, page) => {
  return (dispatch) => {
    postDynamicQueryApi(data, mode)
      .then((response) => {
        if (page.includes("partner")) {
          if (mode === "edit") {
            toast.success("Succesfully updated !");
          } else {
            toast.success("Succesfully added !");
          }
          history.push("/partner-statement");
        }
        if (page.includes("collaboration")) {
          if (mode === "edit") {
            toast.success("Succesfully updated !");
          } else {
            toast.success("Succesfully added !");
          }
          history.push("/collaboration-portal");
        }
        if (page.includes("on-demand-analytics")) {
          if (mode === "edit") {
            toast.success("Succesfully updated !");
          } else {
            toast.success("Succesfully added !");
          }
          history.push("/on-demand-analytics");
        } if (page.includes('dynamic-query')) {

          if (mode === "edit") {
            toast.success("Succesfully updated !");
          } else {
            toast.success("Succesfully added !");
          }
          history.push("/dynamic-query");
        }
      })
      .catch((error) => {
      });
  };
};
const postDynamicQueryApi = (data, mode) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (mode === "edit") {
    result = axios.put("dynamic-query-format/" + data.id, data, {
      headers: headers,
    });
  } else {
    result = axios.post("dynamic-query-format", data, { headers: headers });
  }
  return result;
};

export const runDynamicQuery = (data, id, dq, page, reset, UUID) => {
  let id_1 = JSON.parse(sessionStorage.getItem("dynamic_filter_fields")).id;
  return (dispatch) => {
    dispatch(fetchStart());
    if (reset == "reset") {
      dispatch(fetchEnd());
      dispatch(runDynamicQueryApiSuccess([]));
    } else
      runDynamicQueryApi(data, id, dq, page, reset, UUID)
        .then((response) => {

          if (reset != "reset") {
            if (response.data.response && response.data.response.length === 0) {
              dispatch(fetchEnd());
              toast.warning("No records found");
            }

            else if (
              response.data.response &&
              response.data.response.length > 0
            ) {
              dispatch(runDynamicQueryApiSuccess(response.data.response));
              dispatch(fetchEnd());
              if (dq === "dynamicAnalytics") {
                history.push(
                  "/on-demand-analytics/on-demand-analytics-form/results/" +
                  id_1
                );
              }
              else if (dq === "dq") {
                if (
                  sessionStorage.getItem("dynamic_filter_fields") &&
                  JSON.parse(sessionStorage.getItem("dynamic_filter_fields")).id
                ) {
                  if (page === "collaboration")
                    history.push(
                      "/collaboration-portal/collaboration-portal-form/results/" +
                      id_1
                    );
                  else if (page === "paymentpartner") {
                    history.push(
                      "/partner-statement/partner-statement-form/results/" +
                      id_1
                    );
                  } else {
                    //if (localStorage.getItem("SFDQ"))
                    if (reset == 'sync-data')
                      history.push(
                        "/dynamic-query/dynamic-query-form-sf/results/" + id_1
                      );
                    else
                      history.push(
                        "/dynamic-query/dynamic-query-form/results/" + id_1
                      );
                  }
                }
              } else {
                if (page === "collaboration")
                  history.push(
                    "/collaboration-portal/collaboration-portal-form/results/" +
                    id_1
                  );
                else if (page === "paymentpartner") {
                  history.push(
                    "/partner-statement/partner-statement-form/results/" + id_1
                  );
                } else {
                  if (!(sessionStorage.getItem("SFDQ"))) {
                    history.push(
                      "/dynamic-query/dynamic-query-form/results/" + id_1
                    );
                  } else {

                    history.push(
                      "/dynamic-query/dynamic-query-form-sf/results/" + id_1
                    );
                  }
                }
              }
            }
          }
        })
        .catch((error) => {
          dispatch(fetchEnd());
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message && error.response.data.status == 413
          ) {

            toast.warning(error.response.data.message);
          }
        });
  };
};

const runDynamicQueryApi = (data, id, dq, page, reset, UUID) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (reset === "reset") {
    result = [];
  } else {
    if (UUID) {
      if (sessionStorage.getItem("SFDQ"))
        result = axios.post("dynamic-query?limit=0&request_id=" + UUID, data, { headers: headers });
      else result = axios.post("dynamic-query?request_id=" + UUID, data, { headers: headers });
    } else {
      if (sessionStorage.getItem("SFDQ"))
        result = axios.post("dynamic-query?limit=0", data, { headers: headers });
      else result = axios.post("dynamic-query", data, { headers: headers });
    }
  }
  return result;
};

export const runDynamicQueryApiSuccess = (data) => {
  return {
    type: QUERY_RESULTS_FINAL_SUCCESS,
    data,
  };
};

export const downloadDynamicQuery = (data, type, email, page) => {
  return (dispatch) => {
    dispatch(fetchStart())
    downloadDynamicQueryAPI(data, type, email)
      .then((response) => {
        dispatch(fetchEnd())
        if (type == 'email') {
          toast.success(response.data.response)

        }
        else {
          let [data, data2] = response.headers["content-type"].split(" ");
          toast("Please wait." + type == 'pdf' ? 'Pdf' : 'Sheet' + " is getting downloaded.");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          let date = Date.now();
          link.setAttribute("download", data2); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        if (page.includes("collaboration"))
          history.push('/collaboration-portal')
        else if (page.includes("partner"))
          history.push('/partner-statement')
        else
          history.push('/dynamic-query')
      })
      .catch((error) => {
        dispatch(fetchEnd())
        //toast.error("Something went wrong. Please try again later.");
      });
  };
};
const downloadDynamicQueryAPI = (data, type, email) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (type == 'email')
    result = axios.post("download-dynamic-query-result?send_mail=true&&email_address=" + email, data, {
      headers: headers
    });
  else if (type == 'pdf')
    result = axios.post("/download-dynamic-query-result-pdf", data, {
      headers: headers,
      responseType: "blob",
    });
  else
    result = axios.post("download-dynamic-query-result", data, {
      headers: headers,
      responseType: "blob",
    });
  return result;
};

//Download and Upload Migration Management Data

export const downloadMigrationManagementData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart())
    downloadMigrationManagementDataAPI(data)
      .then((response) => {
        dispatch(fetchEnd())
        // const contentDisposition = response.headers['content-disposition'];
        // const filename = contentDisposition ? contentDisposition.split('filename=')[1].split(';')[0] : `migration_data_${new Date().toISOString().split('T')[0]}.json`;
        const filename = `${data}_data.json`;
        // Convert the response data to JSON string
        const jsonData = JSON.stringify(response.data);
        toast("Please wait. The sheet is getting downloaded.");
        const url = window.URL.createObjectURL(new Blob([jsonData], { type: 'application/json' }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        history.push('/migration-management')
      })
      .catch((error) => {
        dispatch(fetchEnd())
        //toast.error("Something went wrong. Please try again later.");
      });
  };
};
const downloadMigrationManagementDataAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("migration?table_names=" + data, {
    headers: headers
  });
  return result;
};

export const uploadMigrationManagementData = (file, requester, selectedObjects) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadMigrationManagementDataAPI(file, requester, selectedObjects)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success("File have been successfully updated.");
      })
      .catch((error) => {
        dispatch(fetchEnd());
        toast.error(ServerError);
      });
  };
};
const uploadMigrationManagementDataAPI = (file, requester, selectedObjects) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  // let result = axios.post("migration/upload?table_name=" + selectedObjects +
  //   "&organization_id=" + requester, file, {
  //   headers: headers,
  let result = axios.post("migration/upload", file, {
    headers: headers,
  });
  return result;
};

//run dynamic query
export const getDynamicQuery = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getDynamicQueryApi(id).then((response) => {
      dispatch(fetchEnd());
      dispatch(getDynamicQueryApiSuccess(response.data.response));
      // if (response.data && response.data.response && response.data.response.table_name &&
      //     response.data.response.table_name === 'sales_data') {
      //     dispatch(getDynamicQuerySalesDataFields());
      // } else if (response.data && response.data.response && response.data.response.table_name &&
      //     response.data.response.table_name === 'financial_postings') {
      //     dispatch(getDynamicQueryFields());
      // }
      // else if (response.data && response.data.response && response.data.response.table_name &&
      //     response.data.response.table_name === 'purchase_data') {
      //     dispatch(getPurchaseDataFields());
      // } else {
      //     dispatch(getCalculationSimulationDataFields());
      // }
    });
  };
};

const getDynamicQueryApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("dynamic-query-format?format_id=" + id, {
    headers: headers,
  });
  return result;
};

export const deleteDynamicQuery = (id, pagination, limit, queryType, app) => {
  return (dispatch) => {
    deleteDynamicQueryAPI(id).then(
      (response) => {
        dispatch(getQueryList(pagination, limit, queryType, app));
        toast.success("Query deleted successfully");
      }
    );
  };
};

const deleteDynamicQueryAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.delete("dynamic-query-format?id=" + id, { headers: headers });
  return result;
};

export const cloneDynamicQuery = (id, pagination, limit, queryType, app) => {
  return (dispatch) => {
    cloneDynamicQueryAPI(id).then(
      (response) => {
        toast.success("Query clonned successfully");
        dispatch(getQueryList(pagination, limit, queryType, app));
      }
    );
  };
};

const cloneDynamicQueryAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.post("dynamic-query-format/clone/" + id, { headers: headers });
  return result;
};

export const getDynamicQueryApiSuccess = (data) => {
  return {
    type: DYNAMIC_QUERY_DETAILS_SUCCESS,
    data,
  };
};

//get claims format fields
export const getClaimsFormatFields = () => {
  return (dispatch) => {
    getClaimsFormatFieldsAPI().then((response) => {
      dispatch(getClaimsFormatFieldsSuccess(response.data.response));
    });
  };
};

const getClaimsFormatFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("claim-format-fields", { headers: headers });
  return result;
};

export const getClaimsFormatFieldsSuccess = (data) => {
  sessionStorage.setItem("dq_table_name", data.table_name);
  return {
    type: CLAIMS_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

export const getIncomingClaimsQuery = (id) => {
  return (dispatch) => {
    getIncomingClaimsQueryAPI(id).then((response) => {
      dispatch(getIncomingClaimsQuerySuccess(response.data.response));
      if (
        response.data &&
        response.data.response &&
        response.data.response.table_name &&
        response.data.response.table_name === "sales_data"
      ) {
        dispatch(getDynamicQuerySalesDataFields());
      } else {
        dispatch(getDynamicQueryFields());
      }
    });
  };
};

const getIncomingClaimsQueryAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get(
    "/upload-format" + id + "?app-functionality=IndirectSalesDataFormat",
    { headers: headers }
  );
  return result;
};

export const getIncomingClaimsQuerySuccess = (data) => {
  return {
    type: INCOMING_CLAIM_DETAILS_SUCCESS,
    data,
  };
};

export const loadTargetData = () => {
  return (dispatch) => {
    loadTargetDataAPI().then((response) => {
      dispatch(loadTargetDataAPISuccess(response.data.response));
    });
  };
};

const loadTargetDataAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("target-data-fetch-all", { headers: headers });
  return result;
};

export const loadTargetDataAPISuccess = (data) => {
  return {
    type: LOAD_TARGET_DATA,
    data,
  };
};

export const customerAndSupplierCollaborationPortal = () => {
  return (dispatch) => {
    customerAndSupplierCollaborationPortalAPI()
      .then((response) => {
        dispatch(
          customerAndSupplierCollaborationPortalSuccess(response.data.response)
        );
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.message
        ) {
          //toast.error(error.response.data.error.message);
        }
      });
  };
};

const customerAndSupplierCollaborationPortalAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("collaboration-portal-attrs", { headers: headers });
  return result;
};

export const customerAndSupplierCollaborationPortalSuccess = (data) => {
  return {
    type: COLL_PORTAL_CUST_SUPP,
    data,
  };
};

export const getOutgoingClaimsFormatFields = () => {
  return (dispatch) => {
    getOutgoingClaimsFormatFieldsAPI().then((response) => {
      dispatch(getOutgoingClaimsFormatFieldsSuccess(response.data.response));
    });
  };
};

const getOutgoingClaimsFormatFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("claim-response-fields", { headers: headers });
  return result;
};

export const getOutgoingClaimsFormatFieldsSuccess = (data) => {
  return {
    type: OUTGOING_CLAIMS_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

export const getOutgoingClaimsQuery = (id) => {
  return (dispatch) => {
    getOutgoingClaimsQueryAPI(id).then((response) => {
      dispatch(getOutgoingClaimsQuerySuccess(response.data.response));
    });
  };
};

const getOutgoingClaimsQueryAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("claim-response-format?format_id=" + id, {
    headers: headers,
  });
  return result;
};

export const getOutgoingClaimsQuerySuccess = (data) => {
  return {
    type: OUTGOING_CLAIM_DETAILS_SUCCESS,
    data,
  };
};

export const getDynamicQueryFieldsNew = (appType, tableName, loading) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getDynamicQueryFieldsNewApi(appType, tableName, loading).then((response) => {
      if (loading !== "No") {
        dispatch(fetchEnd());
      }
      dispatch(getDynamicQueryFieldsNewApiSuccess(response.data.response));
    });
  };
};

const getDynamicQueryFieldsNewApi = (appType, tableName) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get(
    "dynamic-query-fields?app_type=" +
    appType +
    "&table_description=" +
    tableName,
    { headers: headers }
  );
  return result;
};

export const getDynamicQueryFieldsNewApiSuccess = (data) => {
  return {
    type: DYNAMIC_QUERY_FIELDS_SUCCESS_NEW,
    data,
  };
};

export const getAllOrganizationUsers = () => {
  return (dispatch) => {
    getAllOrganizationUsersAPI()
      .then((response) => {
        if (response.data && response.data.response)
          dispatch(getAllOrgUser(response.data.response));
      })
      .catch((error) => { });
  };
};

const getAllOrganizationUsersAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result = axios.get("org-users", { headers: headers });
  return result;
};

export const getAllOrgUser = (data) => {
  return {
    type: GET_ALL_ORG_USERS,
    data,
  };
};

export const getTableDeterminationData = (applicationType) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getTableDeterminationDataAPI(applicationType).then((response) => {
      dispatch(fetchEnd());
      dispatch(getTableDeterminationDataAPISuccess(response.data.response));
    });
  };
};

const getTableDeterminationDataAPI = (applicationType) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (applicationType) {
    result = axios.get("dynamic-query-table-determination?application_type=" + applicationType, { headers: headers });
  } else {
    result = axios.get("dynamic-query-table-determination", { headers: headers });
  }
  return result;
};

export const getTableDeterminationDataAPISuccess = (data) => {
  return {
    type: TABLE_DETERMINATION_SUCCESS,
    data,
  };
};

//run predefinedQuery
export const runPredefinedQuery = (data, id, uuid) => {
  return (dispatch) => {
    dispatch(fetchStart());
    runPredefinedQueryApi(data, id, uuid)
      .then((response) => {
        dispatch(fetchEnd());
        //if (reset != 'reset') {
        if (response.data.response && response.data.response.length === 0) {
          toast.warning("No records found");
        } else if (
          response.data.response &&
          response.data.response.length > 0
        ) {
          dispatch(runPredefinedQueryApiSuccess(response.data.response));

          history.push("/predefined-query/predefined-query-form/results/" + id);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.complete_error_message
        ) {
          //toast.error(error.response.data.complete_error_message);
        }
      });
  };
};

const runPredefinedQueryApi = (data, id, uuid) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  // if (reset === 'reset') {
  //     result = [];
  // } else {
  result = axios.post("run-sql-work-bench/" + id + "?request_id=" + uuid, data, { headers: headers });

  return result;
};

export const runPredefinedQueryApiSuccess = (data) => {
  return {
    type: PREDEFINED_QUERY_RESULTS_FINAL_SUCCESS,
    data,
  };
};

export const getQueryasListSuccess = (data) => {
  return {
    type: QUERY_LIST_SUCCESS,
    data,
  };
};

export const getQueryasList = (pagination, limit) => {
  return (dispatch) => {
    getQueryasListAPI(pagination, limit)
      .then((response) => {
        dispatch(getQueryasListSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getQueryasListAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get(
    "sql-work-bench?page=" +
    pagination +
    "&limit=" +
    limit +
    "&application_type=" +
    sessionStorage.getItem('application'),
    { headers: headers }
  );
  return result;
};

export const getExcelPredefinedFileDownlaod = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart())
    getExcelPredefinedFileDownlaodApi(data, id, type).then((response) => {
      dispatch(fetchEnd())
      if (type === 'Email') {
        toast.success('Email Sent!')
      } else {
        toast("Please wait. File is getting downloaded.");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let [data, data2] = response.headers['content-type'].split(";")
        link.setAttribute("download", data2.trim());
        document.body.appendChild(link);
        link.click();
      }
    })
      .catch((error) => {
        dispatch(fetchEnd())
      });


  };
};

const getExcelPredefinedFileDownlaodApi = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (type === 'Email') {
    result = axios.post("download-sql-work-bench-result/" + id + '?send_mail=true', data, { headers: headers });
  } else {
    result = axios.post("download-sql-work-bench-result/" + id, data, { headers: headers, responseType: "blob" });
  }
  return result;
};

//run dynamic query
export const getPredefinedQuery = (id) => {
  return (dispatch) => {
    getPredefinedQueryApi(id).then((response) => {
      dispatch(getPredefinedQueryApiSuccess(response.data.response));
      // if (response.data && response.data.response && response.data.response.table_name &&
      //     response.data.response.table_name === 'sales_data') {
      //     dispatch(getDynamicQuerySalesDataFields());
      // } else if (response.data && response.data.response && response.data.response.table_name &&
      //     response.data.response.table_name === 'financial_postings') {
      //     dispatch(getDynamicQueryFields());
      // }
      // else if (response.data && response.data.response && response.data.response.table_name &&
      //     response.data.response.table_name === 'purchase_data') {
      //     dispatch(getPurchaseDataFields());
      // } else {
      //     dispatch(getCalculationSimulationDataFields());
      // }
    });
  };
};

const getPredefinedQueryApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("sql-work-bench", { headers: headers });
  return result;
};

export const getPredefinedQueryApiSuccess = (data) => {
  return {
    type: PREDEFINED_QUERY_DETAILS_SUCCESS,
    data,
  };
};

export const downloadPredefinedQuery = (data) => {
  return (dispatch) => {
    downloadPredefinedQueryAPI(data)
      .then((response) => {
        toast("Please wait. Sheet is getting downloaded.");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = Date.now();
        link.setAttribute("download", date + ".xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        //toast.error("Something went wrong. Please try again later.");
      });
  };
};
const downloadPredefinedQueryAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("run-sql-work-bench", data, {
    headers: headers,
    responseType: "blob",
  });
  return result;
};
//query ref


export const getQueryRefData = (id) => {
  return (dispatch) => {
    getQueryRefDataAPI(id).then((response) => {
      dispatch(getQueryRefDataAPISuccess(response.data.response));
    });
  };
};

const getQueryRefDataAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("dynamic-query-filter?query_id=" + id, { headers: headers });
  return result;
};

export const getQueryRefDataAPISuccess = (data) => {
  return {
    type: DYNAMIC_QUERY_FILTER,
    data,
  };
};

export const getQueryRefById = (id) => {
  return (dispatch) => {
    getQueryRefByIdAPI(id).then((response) => {
      dispatch(getQueryRefByIdAPISuccess(response.data.response));
    });
  };
};

const getQueryRefByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  result = axios.get("dynamic-query-filter/" + id, { headers: headers });
  return result;
};

export const getQueryRefByIdAPISuccess = (data) => {
  return {
    type: DYNAMIC_QUERY_FILTER_BY_ID,
    data,
  };
};
export const updateQueryRefData = (type, data, id, page, table, report, Query) => {
  return (dispatch) => {
    if (type == 'create' || type == 'edit')
      dispatch(fetchStart())
    updateQueryRefDataAPI(type, data, id, report, Query).then((response) => {
      toast.success(response.data.response)
      if (type == 'create' || type == 'edit') {
        dispatch(runDynamicQueryApiSuccess(response.data.response));
        dispatch(fetchEnd());
        let id_1 = data && data.query_id ? data.query_id : ''
        if (page.includes("collaboration"))
          history.push(
            "/collaboration-portal/collaboration-portal-form/results/" +
            id_1
          );
        else if (page.includes("partner")) {
          history.push(
            "/partner-statement/partner-statement-form/results/" + id_1
          );
        }
        else if (page.includes("R")) {
        }
        else if (Query === "OnDemand") {
        }
        else {
          if (table == 'sync-data') {
            history.push(
              "/dynamic-query/dynamic-query-form-sf/results/" + id_1
            );
          } else {
            history.push(
              "/dynamic-query/dynamic-query-form/results/" + id_1
            );

          }
        }
      }
      if (type == 'delete') {
        dispatch(getQueryRefData(data))
      }
    })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const updateQueryRefDataAPI = (type, data, id, report, Query) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;
  if (Query === "OnDemand") {
    if (type == 'create')
      if (report) {
        result = axios.post("dynamic-query-filter?functionality=opeational_reports", data, { headers: headers });
      } else {
        result = axios.post("dynamic-query-filter?functionality=dynamic_query", data, { headers: headers });
      }
    else if (type == 'edit')
      if (report) {
        result = axios.put("dynamic-query-filter?functionality=opeational_reports", data, { headers: headers });
      } else {
        result = axios.put("dynamic-query-filter?functionality=dynamic_query", data, { headers: headers });
      }
    else
      if (type == 'deleteAll')
        result = axios.delete("dynamic-query-filter?query_id=" + id, { headers: headers });
      else
        result = axios.delete("dynamic-query-filter?id=" + id, { headers: headers });
  }
  else {
    if (type == 'create')
      if (report) {
        result = axios.post("dynamic-query-filter?functionality=opeational_reports", data, { headers: headers });
      } else {
        result = axios.post("dynamic-query-filter", data, { headers: headers });
      }
    else if (type == 'edit')
      if (report) {
        result = axios.put("dynamic-query-filter?functionality=opeational_reports", data, { headers: headers });
      } else {
        result = axios.put("dynamic-query-filter", data, { headers: headers });
      }
    else
      if (type == 'deleteAll')
        result = axios.delete("dynamic-query-filter?query_id=" + id, { headers: headers });
      else
        result = axios.delete("dynamic-query-filter?id=" + id, { headers: headers });
  }

  return result;
};


export const updateQueryRefDataMaster = (type, data, id, page) => {
  return (dispatch) => {
    if (type == 'create' || type == 'edit')
      dispatch(fetchStart())
    updateQueryRefDataMasterAPI(type, data, id, page).then((response) => {
      toast.success(response.data.response)
      if (type == 'create' || type == 'edit') {
        dispatch(runDynamicQueryApiSuccess(response.data.response));
        dispatch(fetchEnd());
      }
      if (type == 'delete') {
        dispatch(getQueryRefData(data))
      }
    });
  };
};

const updateQueryRefDataMasterAPI = (type, data, id, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  let result;

  if (page == 'document_header') {
    if (type == 'create')
      result = axios.post("dynamic-query-filter?functionality=document_header", data, { headers: headers });
    else if (type == 'edit')
      result = axios.put("dynamic-query-filter?functionality=document_header", data, { headers: headers });
    else if (type == 'deleteAll')
      result = axios.delete("dynamic-query-filter?query_id=" + id, { headers: headers });
    else
      result = axios.delete("dynamic-query-filter?id=" + id, { headers: headers });
  }
  else if (page == 'contract_header') {
    if (type == 'create')
      result = axios.post("dynamic-query-filter?functionality=contract_header", data, { headers: headers });
    else if (type == 'edit')
      result = axios.put("dynamic-query-filter?functionality=contract_header", data, { headers: headers });
    else if (type == 'deleteAll')
      result = axios.delete("dynamic-query-filter?query_id=" + id, { headers: headers });
    else
      result = axios.delete("dynamic-query-filter?id=" + id, { headers: headers });
  }
  else if (page === 'promotions') {
    if (type == 'create')
      result = axios.post("dynamic-query-filter?functionality=promotions", data, { headers: headers });
    else if (type == 'edit')
      result = axios.put("dynamic-query-filter?functionality=promotions", data, { headers: headers });
    else
      if (type == 'deleteAll')
        result = axios.delete("dynamic-query-filter?query_id=" + id, { headers: headers });
      else
        result = axios.delete("dynamic-query-filter?id=" + id, { headers: headers });
  }
  else if (page === 'Build Sales Data') {
    if (type == 'create')
      result = axios.post("dynamic-query-filter?functionality=Batch Job", data, { headers: headers });
    else if (type == 'edit')
      result = axios.put("dynamic-query-filter?functionality=Batch Job", data, { headers: headers });
    else
      if (type == 'deleteAll')
        result = axios.delete("dynamic-query-filter?query_id=" + id, { headers: headers });
      else
        result = axios.delete("dynamic-query-filter?id=" + id, { headers: headers });
  }
  else {
    if (type == 'create')
      result = axios.post("dynamic-query-filter?functionality=master_data", data, { headers: headers });
    else if (type == 'edit')
      result = axios.put("dynamic-query-filter?functionality=master_data", data, { headers: headers });
    else
      if (type == 'deleteAll')
        result = axios.delete("dynamic-query-filter?query_id=" + id, { headers: headers });
      else
        result = axios.delete("dynamic-query-filter?id=" + id, { headers: headers });
  }

  return result;
};